<template>
    <modal ref="modalProProveedores" titulo="Actualizar Proveedores" icon="devolver_productos" tamano="modal-lg" no-aceptar adicional="Actualizar" @adicional="actualizar">
        <div class="row mx-0 text-general justify-center mb-4">
            Actualize los proveedores de los siguientes productos
        </div>
        <div v-for="(p,index) in productos_provedores" :key="index" class="row mx-0 mb-4">
            <div class="col-6 pr-0">
                <div class="row mx-0">
                    <div class="col-9 pr-0 d-flex">
                        <img :src="p.producto.imagen" width="60px" height="60px" class="obj-cover" />
                        <div class="col d-flex flex-column justify-content-around">
                            <p class="text-general f-13 nombre-producto">
                                {{ p.producto.nombre }}
                            </p>
                            <p class="text-general2 f-12">
                                {{ p.producto.presentacion }}
                            </p>
                            <div class="row mx-0">
                                <p class="col px-0 text-general tres-puntos f-12">
                                    {{ convertMoney(p.unidad_final,pedido.idm_moneda) }}
                                </p>
                                <p class="col text-center px-0 tres-puntos text-general f-12">
                                    <span>{{ p.cantidad }}</span> {{ p.producto.unidad }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col px-0">
                <div class="row mx-0 h-100 align-items-end justify-content-between">
                    <div class="col-3">
                        <p class="text-general f-13 pl-2"> Inventario</p>
                        <p class="text-general f-13 pl-2"> Proveedor</p>
                        <el-tooltip placement="bottom" effect="light">
                            <div slot="content">
                                Seleccione esta opción solamente si este producto<br/>
                                sera recogido en las instalaciones del proveedor <br/>
                                y llevado directamente al cliente, <br/>
                                sin pasar por la bodega del cedis.
                            </div>
                            <el-checkbox v-model="p.inventario_proveedor" :true-label="1" :false-label="0" class="check-red pl-3" />
                        </el-tooltip>
                    </div>
                    <div class="col-7 px-1">
                        <p class="text-general f-13 pl-2">Seleccione Proveedor</p>
                        <el-select v-model="p.id_proveedor" clearable placeholder="Seleccione Proveedor" size="small" class="w-100">
                            <el-option
                            v-for="item in provedores"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import axios from 'axios'
import Pedidos from '~/services/pedidos/pedidos-admin'
import {mapGetters} from 'vuex'
export default {
    props:{
        pedido:{
            type:Object,
            required:true
        }
    },
    data(){
        return {
            provedores:[],
            listado_productos:[],
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            productos_provedores: 'pedidos/pedidos_admin/productos_provedores',
        }),
    },
    methods: {
        toggle(){
            this.select_proveedores()
            this.$refs.modalProProveedores.toggle();
        },
        async select_proveedores(){

            const {data} = await axios(`proveedores/select?id_cedis=${this.pedido.id_cedis}`)
            this.provedores = data.provedores
        },
        async actualizar(){
            try {

                let productos = []
                for (const p of this.productos_provedores){
                    productos.push({
                        id_pp:p.id,
                        id_producto:p.id_producto,
                        inventario_proveedor:p.inventario_proveedor,
                        id_proveedor:p.id_proveedor == '' ? null : p.id_proveedor
                    })

                }

                let form = {
                    id_cedis:this.pedido.id_cedis,
                    id_pedido:this.id_pedido,
                    productos
                }
                const {data} = await Pedidos.actualizar_pvs(form)
                this.$emit('enviar')
                this.notificacion('','Registros actualizados correctamente','success')
                this.$refs.modalProProveedores.toggle();

            } catch (e){
                this.error_catch(e)
            }

        }
    }
}
</script>

<style lang="scss" scoped>
.nombre-producto{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 19px;
}
</style>
